export const organizeAndSortFonts = (fonts) => {
  const teamFonts = [...new Set(
    fonts
      .filter(font => font.team !== "Global")
      .map(font => font.name)
  )].sort();

  const globalFonts = [...new Set(
    fonts
      .filter(font => font.team === "Global")
      .map(font => font.name)
  )].sort();

  return [...teamFonts, ...globalFonts];
};

export const injectFonts = (fonts) => {
  let styleElement = document.getElementById("dynamic-font-styles");
  if (!styleElement) {
    styleElement = document.createElement("style");
    styleElement.id = "dynamic-font-styles";
    document.head.appendChild(styleElement);
  }

  const fontStyles = fonts.map((font) => {
    if (styleElement.textContent?.includes(`font-family: '${font.name}';`)) return '';
    const fontStyle = font.is_italic ? "italic" : "normal";
    return `
      @font-face {
        font-family: '${font.name}';
        font-style: ${fontStyle};
        font-weight: ${font.weight};
        src: url('${font.url}') format('${font.format}');
        }
    `;
  });

  styleElement.innerHTML = fontStyles.join("\n");
};

const weightTable = {
  100: "Thin",
  200: "Extra Light",
  300: "Light",
  400: "Normal",
  500: "Medium",
  600: "Semi Bold",
  700: "Bold",
  800: "Extra Bold",
  900: "Black",
}

export const createWeightList = (weights) => {
  if (weights?.length === 0) return [];
  return weights.map((w) => ({ label: weightTable[w], value: w }));
}
