import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name: '',
  activeSettings: null,
  currPage: '',
  fieldSettings: [],
  url: '',
  allowMultipleSubmissions: false,
  backgroundImage: {},
  formImage: {},
  images: {},
  accessType: '',
  style: {
    formStyle: {
      colourTheme: '#FFFFFF',
      formColour: '#FFFFFF',
      formImageUrl: '',
      formSize: 'cover',
      formPosition: 'center',
      textColour: '#000000',
      linkColour: '#0066cc',
      verticalMargins: 34,
      horizontalMargins: 34,
    },
    buttonStyle: {
      buttonColour: '#109CF1',
      textColour: '#FFFFFF',
      rounded: false,
    },
    backgroundStyle: {
      backgroundColour: '#ECEFF3',
      backgroundImageUrl: '',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    fieldStyle: {
      backgroundColor: '#FFFFFF',
      placeholderColor: '#91A0B7',
      borderColor: '#c2cfe0',
      lightBorders: false
    },
    fontStyle: {
      global: 'Poppins',
      headline: null,
      paragraph: null,
      input: null,
      button: null,
      fonts: []
    }
  },
  errors: [],
  isUnsaved: false,
};

export const formsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    setFormName: (state, action) => {
      state.name = action.payload;
    },
    setFormUrl: (state, action) => {
      state.url = action.payload;
    },
    setAllowMultipleSubmissions: (state, action) => {
      state.allowMultipleSubmissions = action.payload;
    },
    setAccessType: (state, action) => {
      state.accessType = action.payload;
    },
    setCurrPage: (state, action) => {
      state.currPage = action.payload;
    },
    setFieldSettings: (state, action) => {
      const currPageindex = state.fieldSettings
        .findIndex((object) => object?.pageName === state.currPage);
      const index = state.fieldSettings[currPageindex]
        ?.fields?.findIndex((object) => object?.field_id === action.payload.field_id);
      if (currPageindex !== -1 && index !== -1) {
        state.fieldSettings[currPageindex].fields[index] = action.payload;
      } else {
        if (!state.fieldSettings[currPageindex]) {
          state.fieldSettings.push({
            pageName: state.currPage,
            fields: [],
          });
        }
        state.fieldSettings[state.fieldSettings
          .findIndex((object) => object?.pageName === state.currPage)]?.fields.push(action.payload);
      }
    },
    deleteFieldSettings: (state, action) => {
      state.fieldSettings[state.currPage] = state.fieldSettings[state.currPage]
        .filter((elem) => elem.field_id !== action.payload);
    },
    setFormStyle: (state, action) => {
      state.style = {
        formStyle: {
          colourTheme: action.payload.formStyle.colourTheme,
          formColour: action.payload.formStyle.formColour,
          formImageUrl: action.payload.formStyle.formImageUrl,
          formSize: action.payload.formStyle.formSize,
          formPosition: action.payload.formStyle.formPosition,
          textColour: action.payload.formStyle.textColour,
          linkColour: action.payload.formStyle.linkColour,
          verticalMargins: action.payload.formStyle.verticalMargins,
          horizontalMargins: action.payload.formStyle.horizontalMargins,
        },
        buttonStyle: {
          buttonColour: action.payload.buttonStyle.buttonColour,
          textColour: action.payload.buttonStyle.textColour,
          rounded: action.payload.buttonStyle.rounded,
        },
        backgroundStyle: {
          backgroundColour: action.payload.backgroundStyle.backgroundColour,
          backgroundImageUrl: action.payload.backgroundStyle.backgroundImageUrl,
          backgroundSize: action.payload.backgroundStyle.backgroundSize,
          backgroundPosition: action.payload.backgroundStyle.backgroundPosition,
        },
        fieldStyle: {
          backgroundColor: action.payload.fieldStyle.backgroundColor,
          placeholderColor: action.payload.fieldStyle.placeholderColor,
          borderColor: action.payload.fieldStyle.borderColor,
          lightBorders: action.payload.fieldStyle.lightBorders,
        },
        fontStyle: {
          global: action.payload.fontStyle.global,
          headline: action.payload.fontStyle.headline,
          paragraph: action.payload.fontStyle.paragraph,
          input: action.payload.fontStyle.input,
          button: action.payload.fontStyle.button,
          fonts: action.payload.fontStyle.fonts,
        },
      };
    },
    setActiveSettings: (state, action) => {
      state.activeSettings = action.payload;
    },
    clearActiveSettings: (state) => {
      state.activeSettings = null;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    isUnsavedForm: (state, action) => {
      state.isUnsaved = action.payload;
    },
    removeErrors: (state, action) => {
      state.errors = state.errors.filter((elem) => elem.field_id !== action.payload);
    },
    clearFormState: () => initialState,
    loadFieldSettings: (state, action) => {
      const currPageindex = state.fieldSettings
        .findIndex((object) => object?.pageName === action.payload.pageName);
      const index = (state.fieldSettings[currPageindex]?.fields || [])
        .findIndex((object) => object?.field_id === action.payload.field.field_id
        || object?.id === action.payload.field.id);

      let processedField = action.payload.field;
      if (action.payload.field?.options) {
        processedField = {
          ...action.payload.field.options,
          ...action.payload.field,
          field_id: action.payload.field.id,
        };
        delete processedField.options;
        delete processedField.id;
      }
      if (currPageindex !== -1 && index !== -1) {
        state.fieldSettings[currPageindex].fields[index] = processedField;
      } else {
        if (!state.fieldSettings[currPageindex]) {
          state.fieldSettings.push({
            pageName: action.payload.pageName,
            fields: [],
          });
        }
        state.fieldSettings[state.fieldSettings
          .findIndex((object) => object?.pageName === action.payload.pageName)]?.fields.push(processedField);
      }
    },
    removeFieldSettings: (state, action) => {
      const currPageindex = state.fieldSettings.findIndex((object) => object?.pageName === action.payload.pageName);
      if (currPageindex === -1) return;
      const index = (
        state.fieldSettings[currPageindex]?.fields || []).findIndex(
        (object) => object?.field_id === action.payload.field.field_id || object?.field_id === action.payload.field.id,
      );
      if (index !== -1) {
        state.fieldSettings[currPageindex].fields.splice(index, 1);
      }
    },
    initializeFieldSettings: (state) => {
      const hasMainForm = state.fieldSettings.some((settings) => settings.pageName === 'main-form');
      if (!hasMainForm) {
        state.fieldSettings.push({
          pageName: 'main-form',
          fields: [],
        });
      }
      const hasThankYouForm = state.fieldSettings.some((settings) => settings.pageName === 'thank-you');
      if (!hasThankYouForm) {
        state.fieldSettings.push({
          pageName: 'thank-you',
          fields: [],
        });
      }
    },
    updateFieldSettingsForPage: (state, action) => {
      const { pageName, fields } = action.payload;
      const pageIndex = state.fieldSettings.findIndex((page) => page.pageName === pageName);

      if (pageIndex !== -1) {
        state.fieldSettings[pageIndex].fields = fields;
      } else {
        state.fieldSettings.push({
          pageName,
          fields,
        });
      }
    },
    setImageFile: (state, action) => {
      const { fieldId, file } = action.payload;
      if (!state.images[fieldId]) {
        state.images[fieldId] = [];
      }
      state.images[fieldId].push(file);
    },
    setBackgroundFile: (state, action) => {
      const { file } = action.payload;
      state.backgroundImage = file;
    },
    setFormImageFile: (state, action) => {
      const { file } = action.payload;
      state.formImage = file;
    },
    removeImageFile: (state, action) => {
      const { fieldId } = action.payload;
      
      if (state.images[fieldId] && state.images[fieldId].length > 0) {
        state.images[fieldId].pop();
      }
      
      if (state.images[fieldId] && state.images[fieldId].length === 0) {
        delete state.images[fieldId];
      }
    },
    setTabPages: (state, action) => {
      state.tabPages = action.payload;
    },
    setBackgroundSize: (state, action) => {
      state.style.backgroundStyle.backgroundSize = action.payload;
    },
    setBackgroundPosition: (state, action) => {
      state.style.backgroundStyle.backgroundPosition = action.payload;
    },
    setFormSize: (state, action) => {
      state.style.formStyle.formSize = action.payload;
    },
    setFormPosition: (state, action) =>{
      state.style.formStyle.formPosition = action.payload;  
    },
    setGlobalFont: (state, action) =>{
      state.style.fontStyle.global = action.payload;
    },
    setHeadlineFont: (state, action) =>{
      state.style.fontStyle.headline = action.payload;
    },
    setParagraphFont: (state, action) =>{
      state.style.fontStyle.paragraph = action.payload;
    },
    setInputFont: (state, action) =>{
      state.style.fontStyle.input = action.payload;
    },
    setButtonFont: (state, action) =>{
      state.style.fontStyle.button = action.payload;
    },
    setFonts: (state, action) => {
      state.style.fontStyle.fonts = action.payload;
    },
    setFormLinkColor: (state, action) => {
      state.style.formStyle.linkColour = action.payload;
    },
    setFieldBackgroundColor: (state, action) => {
      state.style.fieldStyle.backgroundColor = action.payload;
    },
    setFieldPlaceholderColor: (state, action) => {
      state.style.fieldStyle.placeholderColor = action.payload;
    },
    setFieldBorderColor: (state, action) => {
      state.style.fieldStyle.borderColor = action.payload;
    },
    setFieldLightBorders: (state, action) => {
      state.style.fieldStyle.lightBorders = action.payload;
    },
    setVerticalMargins: (state, action) => {
      state.style.formStyle.verticalMargins = action.payload;
    },
    setHorizontalMargins: (state, action) => {
      state.style.formStyle.horizontalMargins = action.payload;
    },
    setButtonRounded: (state, action) => {
      state.style.buttonStyle.rounded = action.payload;
    },
  },
});

export const {
  setFormName,
  setFormUrl,
  setAllowMultipleSubmissions,
  setAccessType,
  setCurrPage,
  setFieldSettings,
  deleteFieldSettings,
  setFormStyle,
  setActiveSettings,
  clearActiveSettings,
  setErrors,
  isUnsavedForm,
  removeErrors,
  clearFormState,
  loadFieldSettings,
  initializeFieldSettings,
  removeFieldSettings,
  updateFieldSettingsForPage,
  setImageFile,
  removeImageFile,
  setBackgroundFile,
  setFormImageFile,
  setTabPages,
  setBackgroundSize,
  setBackgroundPosition,
  setFormSize,
  setFormPosition,
  setGlobalFont,
  setHeadlineFont,
  setParagraphFont,
  setInputFont,
  setButtonFont,
  setFonts,
  setFormLinkColor,
  setFieldBackgroundColor,
  setFieldPlaceholderColor,
  setFieldBorderColor,
  setFieldLightBorders,
  setVerticalMargins,
  setHorizontalMargins,
  setButtonRounded,
} = formsSlice.actions;

// Add FormsReducer
export const selectFormName = (state) => state.formsReducer.name;
export const selectFormUrl = (state) => state.formsReducer.url;
export const selectAllowMultipleSubmissions = (state) => state.formsReducer.allowMultipleSubmissions;
export const selectAccessType = (state) => state.formsReducer.accessType;
export const selectCurrPage = (state) => state.formsReducer.currPage;
export const selectFieldSettings = (state) => state.formsReducer.fieldSettings;
export const selectFormStyle = (state) => state.formsReducer.style;
export const selectActiveSettings = (state) => state.formsReducer.activeSettings;
export const selectErrors = (state) => state.formsReducer.errors;
export const selectFormStatus = (state) => state.formsReducer.status;
export const selectImageByFieldId = (state, fieldId) => state.formsReducer.images[fieldId];
export const selectAllImages = (state) => state.formsReducer.images;
export const selectBackgroundImage = (state) => state.formsReducer.backgroundImage;
export const selectFormImage = (state) => state.formsReducer.formImage;
export const selectFormColor = (state) => state.formsReducer.style.formStyle.formColour;
export const selectFormTextColor = (state) => state.formsReducer.style.formStyle.textColour;
export const selectFormLinkColor = (state) => state.formsReducer.style.formStyle.linkColour;
export const selectButtonColor = (state) => state.formsReducer.style.buttonStyle.buttonColour;
export const selectButtonTextColor = (state) => state.formsReducer.style.buttonStyle.textColour;
export const selectBackgroundColor = (state) => state.formsReducer.style.backgroundStyle.backgroundColour;
export const selectFormImageUrl = (state) => state.formsReducer.style.formStyle.formImageUrl;
export const selectBackgroundImageUrl = (state) => state.formsReducer.style.backgroundStyle.backgroundImageUrl;
export const selectBackgroundSize = (state) => state.formsReducer.style.backgroundStyle.backgroundSize;
export const selectBackgroundPosition = (state) => state.formsReducer.style.backgroundStyle.backgroundPosition;
export const selectFormSize = (state) => state.formsReducer.style.formStyle.formSize;
export const selectFormPosition = (state) => state.formsReducer.style.formStyle.formPosition;
export const selectTabPages = (state) => state.segmentReducer.tabPages;
export const selectGlobalFont = (state) => state.formsReducer.style.fontStyle.global;
export const selectHeadlineFont = (state) => state.formsReducer.style.fontStyle.headline;
export const selectParagraphFont = (state) => state.formsReducer.style.fontStyle.paragraph;
export const selectInputFont = (state) => state.formsReducer.style.fontStyle.input;
export const selectButtonFont = (state) => state.formsReducer.style.fontStyle.button;
export const selectFonts = (state) => state.formsReducer.style.fontStyle.fonts;
export const selectFieldBackgroundColor = (state) => state.formsReducer.style.fieldStyle.backgroundColor;
export const selectFieldPlaceholderColor = (state) => state.formsReducer.style.fieldStyle.placeholderColor;
export const selectFieldBorderColor = (state) => state.formsReducer.style.fieldStyle.borderColor;
export const selectFieldLightBorders = (state) => state.formsReducer.style.fieldStyle.lightBorders;
export const selectVerticalMargins = (state) => state.formsReducer.style.formStyle.verticalMargins;
export const selectHorizontalMargins = (state) => state.formsReducer.style.formStyle.horizontalMargins;
export const selectButtonRounded = (state) => state.formsReducer.style.buttonStyle.rounded;


export default formsSlice.reducer;
